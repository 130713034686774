<template>
<div>
    
    <div class="dashboard-widget-tabs p-0">
        
        <div class="dashboard-widget-tabs-content p-0 mb-0 d-flex border-0">
            <div class="stocks-header tgju-widget light has-icon profile-header" style="overflow:unset;">
                <div class="profile-header-card" :style="showCover()" style="border-bottom-right-radius: 0;border-bottom-left-radius: 0;">
                    <!-- <div class="demo-tag-mobile"><span>آزمایشی</span></div> -->
                     
                    <div class="profile-card-avatar avatar-mobile-set">
                        <div class="profile-card-avatar-pic" :style="showAvatar()">
                            <a v-if="currentRoute == 'ProfileEdit'" href="javascript:void(0);" class="dashboard-avatar-change" @click="avatarFileDialog()" v-tooltip="'تغییر آواتار کاربری'">
                                <input type="file" id="avatar" class="d-none" @change="uploadAttachment($event.target.files,'avatar')">
                                <i class="fa fa-camera" aria-hidden="true"></i>
                            </a>
                        </div>
                        <slot v-if="currentRoute == 'ProfileEdit'">
                            <div v-if="avatarExist" class="delete-avatar"><i class="uil uil-times" @click.prevent="removeAvatar()" v-tooltip="'حذف آواتار'"></i></div>
                        </slot>
                        <div v-if="header_detail.analyst_rating" class="star-row">
                            <i v-for="index in header_detail.analyst_rating" :key="index" class="fa fa-star" aria-hidden="true"></i>
                            <i v-for="index in (5 - header_detail.analyst_rating)" :key="index" class="fa fa-star-o" aria-hidden="true"></i>
                        </div>
                    </div>
                    <div class="profile-card-content">
                        <div class="profile-card-title" data-target="profile-card-title">
                            <h2>
                                {{ !this.$route.params.username ? ( this.$helpers.userInfo().firstname +'  '+ this.$helpers.userInfo().lastname ) : (profileUser.firstname + ' ' + profileUser.lastname) }}
                                <div v-if="header_detail.tick_mark == null" v-tooltip="'سطح کاربری'" data-target="crypto-exchanges-local-tour-step-1" class="user-tag">کاربر‌ عادی</div>
                                <div v-if="header_detail.tick_mark == 'analyst'" v-tooltip="'سطح کاربری'" data-target="crypto-exchanges-local-tour-step-1" class="user-tag user-tag-primary">تحلیل‌گر</div>
                                <div v-if="header_detail.tick_mark == 'admin'" v-tooltip="'سطح کاربری'" data-target="crypto-exchanges-local-tour-step-1" class="user-tag user-tag-warning">پشتیبان</div>
                            </h2>

                            <span class="register-date">
                                <div :style="'display: inline-block;margin-top: -5px;'">
                                    <i class="uil uil-at"></i>
                                    <div style="text-align: right; direction: ltr;display: inherit;font-size: 14px;font-weight: 600;">{{ !this.$route.params.username ? this.$helpers.userInfo().username : profileUser.username }} </div>
                                </div>
                            </span>

                            <span class="register-date">
                                <div :style="'display: inline-block;'">
                                    <i class="uil uil-calendar-alt"></i>
                                    تاریخ‌ عضویت: {{ !this.$route.params.username ? toRelative(this.$helpers.userInfo().created_at) :  toRelative(profileUser.created_at) }}
                                </div>
                            </span>
                        </div>
                        <div class="profile-card-sub" data-target="profile-card-sub">
                            <div class="profile-card-sub-item" v-tooltip="'تعداد دیدگاه‌های کاربر'">
                                <h3>{{ header_detail.comments }}</h3>
                                <span><i class="uil uil-comment-alt-lines"></i> دیدگاه‌ها</span>
                            </div>
                            <div class="profile-card-sub-item" v-tooltip="'تعداد تحلیل‌های کاربر'">
                                <h3>{{ header_detail.technicals }}</h3>
                                <span><i class="uil uil-chart"></i> تحلیل‌ها</span>
                            </div>
                            <div class="profile-card-sub-item" v-tooltip="'تعداد لایک‌های کاربر'">
                                <h3>{{ header_detail.likes }}</h3>
                                <span><i class="uil uil-thumbs-up"></i> لایک‌ها</span>
                            </div>
                        </div>
                    </div>
                    <div class="profile-card-btns">
                        <slot v-if="this.$route.params.username && $helpers.userAuth()">
                            <template v-if="isFollowing">
                                <a href="#" class="tgju-btn tgju-btn-danger" @click.prevent="follow()">
                                    <i class="uil uil-user-times" aria-hidden="true"></i>
                                    عدم دنبال‌کردن
                                </a>
                            </template>
                            <template v-else-if="!isMyProfile">
                                <a href="#" class="tgju-btn tgju-btn-primary" @click.prevent="follow()">
                                    <i class="uil uil-user-plus" aria-hidden="true"></i>
                                    دنبال‌کردن
                                </a>
                            </template>
                        </slot>
                        <div v-if="currentRoute == 'ProfileEdit'" class="dashboard-change-banner">
                            <input type="file" id="cover" class="d-none" @change="uploadAttachment($event.target.files,'cover')">
                            <i class="uil uil-camera" aria-hidden="true" @click.prevent="coverFileDialog()" v-tooltip="'تغییر کاور پروفایل'"></i>
                            <div v-if="coverExist" class="delete-cover"><i class="uil uil-times" @click.prevent="removeCover()" v-tooltip="'حذف کاور'"></i></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="widget-tabs is-flex" style="padding: 15px;" data-target="widget-tabs-nav">
            <div class="widget-tabs-nav">
                <div class="widget-select menu-tags db flex-grow-1 m-left show-769">
                  
                    <vue-select 
                     class="tgju-v-select-m"
                        v-model="selectedCategory"
                        :reduce="(option) => option.id"
                        :clearable="false"
                        label="label"
                        :options="[
                            { label: 'گفت‌وگوها', id: 1 },
                            { label: 'تحلیل‌ها', id: 2 },
                            { label: 'دنبال‌کنندگان', id: 3 },
                            { label: 'دنبال‌شوندگان', id: 4 },
                        ]"
                        @input="setActiveCategory()"
                    />
                </div>

        
                <router-link :to="$route.params.username ? { name : 'ProfileOthers'} : { name: 'Profile' }" class="tgju-btn tgju-btn-light-orange m-left m-bottom-mobile hide-769" :class="{ active: currentRoute == ($route.params.username ? 'ProfileOthers' : 'Profile' )}" data-target="Profile-tab">
                    <i class="uil uil-comment-alt-lines"></i> <span class="hide-m">گفت‌وگوها</span>
                </router-link>
                <router-link :to="$route.params.username ? { name: 'ProfileTechnicalOthers' } : { name : 'ProfileTechnical'}" class="tgju-btn tgju-btn-light-orange m-left m-bottom-mobile hide-769" :class="{ active: currentRoute == ($route.params.username ? 'ProfileTechnicalOthers' : 'ProfileTechnical' )}" data-target="Technical-tab">
                    <i class="uil uil-chart"></i> <span class="hide-m">تحلیل‌ها</span>
                </router-link>
                <router-link :to="$route.params.username ?  { name: 'ProfileFollowersOthers' } : { name : 'ProfileFollowers'}" class="tgju-btn tgju-btn-light-orange m-left m-bottom-mobile hide-769" :class="{ active: currentRoute == ($route.params.username ? 'ProfileFollowersOthers' : 'ProfileFollowers' )}" data-target="Followers-tab">
                    <i class="uil uil-users-alt"></i> <span class="hide-m">دنبال‌کنندگان</span>
                    <div class="tgju-badge tgju-badge-dark hide-m">
                        {{ header_detail.followers }}
                    </div>
                </router-link>
                <router-link :to="$route.params.username ?  { name: 'ProfileFollowingOthers' } : { name : 'ProfileFollowing'}" class="tgju-btn tgju-btn-light-orange m-left m-bottom-mobile hide-769" :class="{ active: currentRoute == ($route.params.username ? 'ProfileFollowingOthers' : 'ProfileFollowing' )}" data-target="Following-tab">
                    <i class="uil uil-users-alt"></i> <span class="hide-m">دنبال‌شوندگان</span>
                    <div class="tgju-badge tgju-badge-dark hide-m">
                        {{ header_detail.followings }}
                    </div>
                </router-link>
            </div>
            <div class="widget-tabs-btn flex-btn">
                <slot v-if="!this.$route.params.username && $helpers.userAuth() && currentRoute == 'ProfileTechnical'">
                    <router-link :to="{ name: 'NewTechnical'}" class="tgju-btn tgju-btn-warning m-left m-bottom-mobile technical-btns-head" data-target="NewTechnical-tab-btn">
                        <i class="uil uil-plus-circle"></i> <span class="hide-m">افزودن تحلیل جدید</span>
                    </router-link>
                    <router-link :to="{ name: 'PublicTechnical'}" class="tgju-btn tgju-btn-light-orange m-left m-bottom-mobile technical-btns-head" data-target="Technical-tab-btn">
                        <i class="uil uil-chart"></i> <span class="hide-m">تابلو تحلیل‌ها</span>
                    </router-link>
                </slot>
                <slot v-if="!this.$route.params.username && $helpers.userAuth()">
                    <router-link :to="{ name: 'ProfileEdit' }" class="tgju-btn tgju-btn-dark-orange tgju-icon-btn" v-tooltip="'ویرایش پروفایل'" :class="{ active: currentRoute == 'ProfileEdit' }" data-target="ProfileEdit-tab-btn">
                        <i class="uil uil-setting"></i>
                    </router-link>
                </slot>
                <dropdown-menu v-model="showShareMenu" class="dashboard-dot-profile-header">
                    <a href="#" v-tooltip="'اشتراک گذاری'" class="tgju-btn tgju-btn-light-orange tgju-icon-btn dropdown-toggle" @click.prevent="" style="margin-right: 0;" data-target="social-tab-btn">
                        <i class="uil uil-ellipsis-v btns-dash-header"></i>
                    </a>
                    <div slot="dropdown">
                        <a class="dropdown-item" @click="socialShare('facebook')"><i class="uil uil-facebook-f" aria-hidden="true"></i>اشتراک در فیسبوک</a>
                        <a class="dropdown-item" @click="socialShare('twitter')"><i class="uil uil-twitter-alt" aria-hidden="true"></i>اشتراک در تویتر</a>
                        <a class="dropdown-item" @click="socialShare('telegram')"><i class="uil uil-telegram-alt" aria-hidden="true"></i>اشتراک در تلگرام</a>
                    </div>
                </dropdown-menu>
                
                <a href="#" @click.prevent="open_help_modal()" class="tgju-btn tgju-btn-light tgju-icon-btn has-tooltip tgju-help-btn" v-tooltip="'راهنما'" style="margin-right: 5px;">
                    <i class="uil uil uil-question-circle"></i>
                </a>


            </div>
        </div>
    </div>
    <div class="technical-btns-tab" v-if="!this.$route.params.username && $helpers.userAuth() && currentRoute == 'ProfileTechnical'">
        <router-link :to="{ name: 'NewTechnical'}" class="tgju-btn tgju-btn-warning NewTechnical-Right" data-target="NewTechnical-tab-btn">
            <i class="uil uil-plus-circle"></i> <span>افزودن تحلیل جدید</span>
        </router-link>
        <router-link :to="{ name: 'PublicTechnical'}" class="tgju-btn tgju-btn-light-orange PublicTechnical-left" data-target="Technical-tab-btn">
            <i class="uil uil-chart"></i> <span>تابلو تحلیل‌ها</span>
        </router-link>
    </div>
</div>
</template>

<script>
import Vue from 'vue';
// این ویو لی اوت هدر در پروفایل می باشد
import moment from "moment-jalaali";
import DropdownMenu from '@innologica/vue-dropdown-menu';
import "vue-swatches/dist/vue-swatches.css";
import vSelect from 'vue-select';
Vue.component('vue-select', vSelect);

export default {
    name: 'App',
    components: {
        'dropdown-menu': DropdownMenu,
    },
    data: function () {
        return {
            selectedCategory: 1,
            show_banner: true,
            isFollowing: true,
            avatarExist: false,
            coverExist: false,
            header_detail: {
                friend_counts: 0,
                twit_counts: 0
            },
            showShareMenu: false,
            profileUser: {
                id: undefined,
                firstname: '',
                lastname: '',
                username: undefined,
                avatar: {
                    name: undefined
                },
                opts: {
                    header_cover: undefined
                }
            },
            currentRoute: this.$router.currentRoute.name,
        }
    },
    mounted() {
        this.$root.$on('increase-followings', () => {
            this.header_detail.followings++;
        });

        this.$root.$on('decrease-followings', () => {
            this.header_detail.followings--;
        });

        // در صورتی که پارامتر نام کاربری ست شده بود
        if (this.$route.params.username) {

            this.getUser(this.$route.params.username);
            if (this.$helpers.userAuth()) {
                this.isFriend(this.$route.params.username);
            }
        }
        // کال کردن این متد جهت دریافت اطلاعات هدر برای پروفایل
        this.headerDetail();
        if(this.$helpers.runHelp('ShowHelp')){
            this.open_help_modal();
        }
    },
    updated() {
        if (this.currentRoute == 'Profile' ||
            this.$store.state.user.username == this.$route.params.username ||
            this.currentRoute == 'ProfileFollowers' ||
            this.currentRoute == 'ProfileFollowing' ||
            this.currentRoute == 'ProfileTechnical') {
            this.$store.state.isMyProfile = true;
        } else {
            this.$store.state.isMyProfile = false;
        }
    },
    methods: {
        setActiveCategory() {
            var avtiveRoute = null;
            switch(this.selectedCategory){
                case 1:
                    avtiveRoute = this.$route.params.username ?  'ProfileOthers':  'Profile';
                    this.$router.push({name: avtiveRoute} );
                    break;
                case 2:
                    avtiveRoute = this.$route.params.username ?  'ProfileTechnicalOthers':  'ProfileTechnical';
                    this.$router.push({name: avtiveRoute} );
                    break;
                case 3:
                    avtiveRoute = this.$route.params.username ?  'ProfileFollowersOthers':  'ProfileFollowers';
                    this.$router.push({name: avtiveRoute} );
                    break;
                case 4:
                    avtiveRoute = this.$route.params.username ?  'ProfileFollowingOthers':  'ProfileFollowing';
                    this.$router.push({name: avtiveRoute} );
                    break;
            }
        },
        
        // این متد برای تغییر و نمایش مناسب زمان استفاده میشود
        toRelative(date) {
            return moment(date).fromNow();
        },
        // این متد جهت ارسال درخواست دریافت اطلاعات مناسب هدر استفاده میشود
        headerDetail() {
            let api_data = {
                username: this.$route.params.username ? this.$route.params.username : this.$helpers.userInfo().username
            };

            this.$helpers.makeRequest('GET', '/user/header-details/', api_data).then(
                api_response => {
                    this.$helpers.api_alert(api_response);

                    if (api_response.status == 200) {
                        this.header_detail = api_response.data;
                    }
                });
        },
        // این متد درخواست فالو و انفالو کاربر مورد نظر را هندل میکند .
        follow() {
            let api_data = {
                action: this.isFollowing ? 'unfollow' : 'follow'
            };

            this.$helpers.makeRequest('GET', '/user/follow/' + this.$route.params.username, api_data).then(
                api_response => {
                    this.$helpers.api_alert(api_response);

                    if (api_response.status == 200) {
                        let response = api_response.data.response;
                        this.isFollowing = !this.isFollowing;
                        if (this.isFollowing) {
                            if (!this.$store.state.isMyProfile) {
                                this.header_detail.followers++;
                            }
                        } else {
                            if (!this.$store.state.isMyProfile) {
                                this.header_detail.followers--;
                            }
                        }
                    }

                    if (api_response.status == 400) {
                        Vue.prototype.$toast.warn({
                            title: 'توجه',
                            message: 'شما پیش تر این کاربر را دنبال کرده اید'
                        });
                    }

                });
        },
        // این متد برای این استفاده میشود که اگر در پروفایل یک کاربر دیگر بودیم مشخص مینماید که آیا
        // این کاربر دنبال کرده ایم یا خیر
        isFriend(username) {
            let api_data = {
                username: username,
            };

            this.$helpers.makeRequest('GET', '/user/is-friend', api_data).then(api_response => {
                this.$helpers.api_alert(api_response);

                if (api_response.status == 200) {
                    this.isFollowing = api_response.data.response.status == 'success' ? true : false;
                }
            });
        },
        // این متد برای به اشتراک گذاشتن پروفایل کاربری استفاده میشود
        socialShare(type) {
            // داینامیک قابلیت اشتراک گذاری پروفایل دیگران و خود
            this.$helpers.socialShare(type, `پروفایل کاربری `, process.env.VUE_APP_URL + '/profile/' + (this.$route
                .params.username ? this.$route.params.username : this.$helpers.userInfo().username));
        },
        // این متد برای سینک نگهداشتن متغیر روت با رویت نیم فعلی
        syncCurrentRout() {
            this.currentRoute = this.$router.currentRoute.name;
        },
        // این متد جهت دریافت اطلاعات کاربر استفاده میشود
        getUser(username) {
            //آماده سازی دیتای درخواست ای پی آی
            let api_data = {
                username: username,
            };

            // ارسال درخواست ای پی آی
            this.$helpers.makeRequest('POST', '/user/get', api_data).then(api_response => {
                this.$helpers.api_alert(api_response);

                // در صورتی در خواست ای پی آی پیام موفقیت داشت		 
                if (api_response.data.response.status === 'success') {
                    this.profileUser = api_response.data.response.detail;
                }

                // در صورتی که کاربر یافت نشد
                if (api_response.data.response.status === 'error') {

                    Vue.prototype.$toast.error({
                        title: 'خطا',
                        message: 'کاربر یافت نشد',
                    });

                    window.location.href = "/profile";
                }

            });
        },
        // این متد برای آپلود تصویر کاربر استفاده میشود
        uploadAttachment(fileList, type) {
            // آرایه ای از خطا های رایج
            let server_response = {
                file_extension_not_allowed: 'فرمت فایل انتخاب شده مجاز نیست',
                file_size_not_allowed: 'اندازه عکس مورد انتخاب شده مجاز نیست',
                file_upload_failed: 'مشکلی در آپلود بوجود آمد',
            };

            let attachment = [];
            if (fileList.length) {
                attachment = fileList[0];
            } else {
                attachment = null;
            }

            if (attachment) {
                let api_data = {
                    attachment,
                    type: type,
                };

                this.$helpers.makeRequest('POST', '/user/' + type + '-upload', api_data).then(api_response => {
                    this.$helpers.api_alert(api_response);

                    if (api_response.status == 400) {
                        Vue.prototype.$toast.error({
                            title: 'خطا',
                            message: server_response[api_response.data.response.message]
                        });

                        return 0;
                    }

                    if (api_response.status == 200) {
                        Vue.prototype.$toast.success({
                            title: 'موفق',
                            message: 'با موفقیت بارگزاری شد'
                        });

                        this.$store.dispatch('setUserData', {
                            forced: true
                        });
                        return 0;
                    }

                });
            }
        },
        // این متد فایل دیالوگ برای انتخاب عکس آواتار کاربر را هندل میکند
        avatarFileDialog() {
            document.getElementById('avatar').click()
        },
        // این متد فایل دیالوگ برای انتخاب عکس کاور کاربر را هندل میکند
        coverFileDialog() {
            document.getElementById('cover').click()
        },
        // این متد برای ساختن لینک نمایش مناسب آواتار کاربر در هدر استفاده میشود
        showCover() {
            let url = undefined;

            if (this.$route.params.username && this.$helpers.isJson(this.profileUser.opts))
                url = this.$helpers.api_url() + this.$helpers.json_pars(this.profileUser.opts)['header_cover']['name'];

            if (!this.$route.params.username && this.$helpers.userInfo().opts.header_cover)
                url = this.$helpers.api_url() + this.$helpers.userInfo().opts.header_cover.name;

            if (url == undefined) {
                this.coverExist = false;
            } else {
                this.coverExist = true;
            }

            // if (url.indexOf('undefined') > -1)
            // 	url = 'https://static.tgju.org/views/default/images/tgju/dash-header-bd.png';

            return `background-image: url("${ !url ? 'https://static.tgju.org/views/default/images/tgju/dash-header-bd.png' : url }");`;
        },
        // برای پاک کردن عکس کاور
        removeCover() {
            let api_data = {
                action: 'delete',
            };

            this.$helpers.makeRequest('POST', '/user/cover-upload', api_data).then(api_response => {
                this.$helpers.api_alert(api_response);

                if (api_response.status == 400) {
                    Vue.prototype.$toast.error({
                        title: 'خطا',
                        message: 'خطایی رخ داده است'
                    });
                    return 0;
                }

                if (api_response.status == 200) {
                    Vue.prototype.$toast.success({
                        title: 'موفق',
                        message: 'با موفقیت کاور حذف شد'
                    });

                    this.$store.dispatch('setUserData', {
                        forced: true
                    });
                    return 0;
                }
            });
        },
        // این متد برای ساختن لینک نمایش مناسب کاور کاربر در هدر استفاده میشود
        showAvatar() {
            let url = undefined;

            if (this.$route.params.username && this.$helpers.isJson(this.profileUser.avatar))
                url = this.$helpers.api_url() + this.$helpers.json_pars(this.profileUser.avatar)['name'];

            if (!this.$route.params.username && this.$helpers.userInfo().avatar)
                url = this.$helpers.api_url() + this.$helpers.userInfo().avatar.name;

            if (url == undefined) {
                this.avatarExist = false;
            } else {
                this.avatarExist = true;
            }

            // if (url.indexOf('undefined') > -1)
            // 	url = 'https://static.tgju.org/views/default/images/tgju-user-avatar.png';

            return `background-image: url("${ !url ? 'https://static.tgju.org/views/default/images/tgju-user-avatar.png' : url }"); background-position: center;background-size: cover;`;
        },
        // پاک کردن عکس اواتار
        removeAvatar() {
            let api_data = {
                action: 'delete',
            };

            this.$helpers.makeRequest('POST', '/user/avatar-upload', api_data).then(api_response => {
                this.$helpers.api_alert(api_response);

                if (api_response.status == 400) {
                    Vue.prototype.$toast.error({
                        title: 'خطا',
                        message: 'خطایی رخ داده است'
                    });
                    return 0;
                }

                if (api_response.status == 200) {
                    Vue.prototype.$toast.success({
                        title: 'موفق',
                        message: 'با موفقیت آواتار حذف شد'
                    });

                    this.$store.dispatch('setUserData', {
                        forced: true
                    });
                    return 0;
                }
            });
        },
        // مودال راهنما
        open_help_modal() {
            let modal_data = {
                modal: 'widget_help_modal',
                component: 'Helps/Profile.vue',
                title: 'پروفایل',
                show: true,
            };
            this.$root.$emit('help_modal', modal_data);
        },
    },
    computed: {
        // مشخص کردن هدر پروفایل خودمان از کاربران دیگر
        isMyProfile() {
            return this.$store.state.isMyProfile;
        }
    },
    watch: {
        // آپدیت هدر با توجه به تغییر کردن روت
        $route(to, from) {
            this.syncCurrentRout();

            if (this.$route.params.username) {

                this.getUser(this.$route.params.username);
                if (this.$helpers.userAuth()) {
                    this.isFriend(this.$route.params.username);
                }
            }
            this.headerDetail();
        },
    },
}
</script>
