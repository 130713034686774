import Vue from 'vue';
window.Vue = Vue;
import App from './App.vue';
import './registerServiceWorker';
import router from './router';
import store from './store';
import Tooltip from 'vue-directive-tooltip';
import vuetify from '@/plugins/vuetify';
import '@mdi/font/css/materialdesignicons.min.css';
import globalJS from './plugins/global';

// if (!globalJS.checkDevice('mobile')) {
    Vue.use(Tooltip);
// }

// const Jquery = require('jquery');
// window.$ = Jquery;
import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';
Vue.use(VueSweetalert2);

import plugins from './plugins/index';
plugins.install(Vue);

Vue.config.productionTip = false;

// بررسی لود کامنت و داشبورد و افزودن لودینک و حذف المانهای اضافه
if(window.location.href.includes('comment?subject')){
    document.body.insertAdjacentHTML('beforeend', '<div class="load-cleaniframe cleaniframe-active"><div class="widget-loading"></div></div>');
    document.body.insertAdjacentHTML('beforeend', '<style>@font-face{font-family:iranyekan;font-style:normal;font-weight:700;src:url("https://static.tgju.org/views/default/fonts/iranyekan/Farsi_numerals_(Non_Standard)/WebFonts/fonts/eot/iranyekanwebbold(fanum).eot");src:url("https://static.tgju.org/views/default/fonts/iranyekan/Farsi_numerals_(Non_Standard)/WebFonts/fonts/eot/iranyekanwebbold(fanum).eot?#iefix") format("embedded-opentype"),url("https://static.tgju.org/views/default/fonts/iranyekan/Farsi_numerals_(Non_Standard)/WebFonts/fonts/woff2/iranyekanwebbold(fanum).woff2") format("woff2"),url("https://static.tgju.org/views/default/fonts/iranyekan/Farsi_numerals_(Non_Standard)/WebFonts/fonts/woff/iranyekanwebbold(fanum).woff") format("woff"),url("https://static.tgju.org/views/default/fonts/iranyekan/Farsi_numerals_(Non_Standard)/WebFonts/fonts/ttf/iranyekanwebbold(fanum).ttf") format("truetype")}@font-face{font-family:iranyekan;font-style:normal;font-weight:400;src:url("https://static.tgju.org/views/default/fonts/iranyekan/Farsi_numerals_(Non_Standard)/WebFonts/fonts/eot/iranyekanwebregular(fanum).eot");src:url("https://static.tgju.org/views/default/fonts/iranyekan/Farsi_numerals_(Non_Standard)/WebFonts/fonts/eot/iranyekanwebregular(fanum).eot?#iefix") format("embedded-opentype"),url("https://static.tgju.org/views/default/fonts/iranyekan/Farsi_numerals_(Non_Standard)/WebFonts/fonts/woff2/iranyekanwebregular(fanum).woff2") format("woff2"),url("https://static.tgju.org/views/default/fonts/iranyekan/Farsi_numerals_(Non_Standard)/WebFonts/fonts/woff/iranyekanwebregular(fanum).woff") format("woff"),url("https://static.tgju.org/views/default/fonts/iranyekan/Farsi_numerals_(Non_Standard)/WebFonts/fonts/ttf/iranyekanwebregular(fanum).ttf") format("truetype")}</style>');
    document.body.classList.add('cleaniframe-body');   
} else {
    document.body.insertAdjacentHTML('beforeend', '<div class="page-preloader"><div id="document-full-load"><div class="document-full-load"></div><div class="loading-content"><div class="loading-logo"></div><div class="loading-logo-name"></div><div class="loading-circle"></div></div></div></div>');
    document.body.insertAdjacentHTML('beforeend', '<div id="tgju-notification"><div class="tgju-notification-icon"><img src="https://static.tgju.org//views/default/images/notification-modal-icon.png" alt="notification"></div><div class="tgju-notification-content"><h2> آیا مایلید از نوسانات بازار آگاه شوید؟ </h2><span> دریافت هشدار در نوسانات قیمت طلا، سکه، دلار، اونس، نفت، بورس و بیت کوین </span><div class="tgju-notification-buttons"><button type="button" class="b-danger" onclick="requestPermission()">بله</button><button type="button" class="b-default" onclick="exit_notification_modal()">خیر</button></div></div></div>');
}

Vue.MessageManager = {
    show: function(type, content, confrim) {
        $('#message-container').html('<div class="tgcss-h-[222px]"></div>');
        setTimeout(function(){
            $('#message-container').html('');
        }, 5000);
    }
};

Vue.prototype.$helpers.setData().then(() => {
    var tagID= '#dashboard-app';
    if(window.location.href.includes('comment?subject')){
        tagID= '#clean-frame';
    }
    const vm = new Vue({
        router,
        store,
        vuetify,
        render: h => h(App)
    }).$mount(tagID)

    Vue.prototype.$vm = vm;

    setTimeout(() => {
        $('body').removeClass('overflow-hidden');
        $('.page-preloader').hide();
        $('.side-buttons').removeClass('strong-hidden');
        $('.side-open-icon').removeClass('strong-hidden');         
    }, 2000);

    setTimeout(() => {
        $('.load-cleaniframe').removeClass('cleaniframe-active');
    }, 2000);

    if (Vue.prototype.$helpers.checkDevice('mobile')) {
        $("html").addClass('app-view');
        $("body").addClass('mobile-app');
        $('#main-header').remove();
        $('#footer').remove();
    }else if(Vue.prototype.$helpers.checkDevice('desktop')){
        $("html").addClass('app-view');
        $("body").addClass('desktop-app');
        $("html").addClass('mini-desktop-menu');
        $("html").addClass('tgju-desktop-app');
        $('#main-header').remove();
        $('#footer').remove();
    }
});